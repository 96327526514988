import { OpenPanel } from "@openpanel/web"

window.isTrackingDisabled = (() => {
  return window?.localStorage?.getItem("openpanel_ignore") === "true"
})()

window.sendEvent = function (name, data = {}) {
  if (window.isTrackingDisabled) {
    return
  }

  return new Promise((resolve) => {
    window.op.track(name, data)
    resolve()
  })
}
;(() => {
  if (window.isTrackingDisabled) {
    return
  }

  const op = new OpenPanel({
    clientId: "",
    trackScreenViews: true,
    trackAttributes: true,
    trackOutgoingLinks: true,
    apiUrl: "/api/pipe",
  })

  if (window?.user?.id) {
    op.identify({
      profileId: window.user.id,
      properties: {
        experiment: window.experimentsString,
      },
    })
  }

  window.op = op
})()
